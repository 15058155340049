<template>
  <div id="app">
    <template v-if="showDialog">
      <div class="modal fade show" style="display: block">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Cat24 FAQs</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                v-on:click="showDialog = false"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <FAQView></FAQView>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                v-on:click="showDialog = false"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop show"></div>
    </template>

    <!--<navigation-bar v-if="currentUser.parseObject != null"></navigation-bar>-->

    <!--<div v-if="currentUser.parseObject != null" class="container-fluid lowz" style="padding-left:5px;padding-right:5px;">-->

    <!--<div class="row">-->
    <!--<div class="col-xs-12">-->

    <!--<user-detail v-if="currentView == 'User Profile'" :user="currentUser.parseObject"></user-detail>-->

    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <div class="container-fluid">
      <div class="row h-100">
        <div class="col-md-2"></div>
        <div class="col-md-8 text-start">
          <img
            :src="this.config.logoSrc"
            alt="Project Logo"
            style="max-height: 100px"
          /><BR />

          <template
            v-if="
              this.customLanguage.deliveryPortal &&
              this.customLanguage.deliveryPortal.headding
            "
          >
            <h3 class="ubuntu bold" style="margin-top: 20px">
              {{ this.customLanguage.deliveryPortal.headding }}
            </h3>
          </template>
          <template v-else>
            <h3 class="ubuntu bold" style="margin-top: 20px">
              Delivery Portal
            </h3>
          </template>
          <hr />
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>

    <div
      class="container-fluid"
      v-if="licenseDetails == null && isLoading == true"
    >
      <div class="row h-100">
        <div class="col-md-4"></div>
        <div class="col-md-4 my-auto">
          <div class="card card-block mx-auto">
            <div class="card-header bg-warning">Loading</div>
            <div class="card-body text-center">
              <p class="card-text">Please wait. Fetching license details.</p>
              <br />
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>

    <div
      class="container-fluid"
      v-if="licenseDetails == null && isLoading == false"
    >
      <div class="row h-100">
        <div class="col-md-4"></div>
        <div class="col-md-4 my-auto">
          <div class="card card-block mx-auto">
            <div class="card-header bg-danger text-white">Invalid License</div>
            <div class="card-body text-center">
              <p class="card-text">
                Please double check the link and try again.
              </p>
              <br />
              <span class="fa fa-warning"></span>
              <br />
              {{ errorText }}
            </div>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>

    <div class="container-fluid" v-if="apiError">
      <div class="row h-100">
        <div class="col-md-4"></div>
        <div class="col-md-4 my-auto">
          <div class="card card-block mx-auto">
            <div class="card-header bg-danger text-white">Connection Error</div>
            <div class="card-body text-center">
              <p class="card-text">Unable to connect, please retry later.</p>
              <br />
              <span class="fa fa-warning"></span>
              <br />
              {{ errorText }}
            </div>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>

    <LoginView
      v-if="licenseDetails != null && isLoading == false && currentUser == null"
    ></LoginView>
    <OrderList
      v-if="
        isLoading == false && currentUser != null && rescheduleOrder == null
      "
    ></OrderList>
    <RescheduleView
      v-if="
        isLoading == false && currentUser != null && rescheduleOrder != null
      "
    ></RescheduleView>
    <FAQView v-if="isLoading == false && showFAQs == true"></FAQView>
    <!-- <h1 v-if="currentUser != null">{{currentUser.email}}</h1> -->
    <!-- <TransactionView v-if="licenseConnectionDetails != null && isLoading == false && currentUser.parseObject != null"></TransactionView> -->
    <div
      class="container-fluid"
      style="margin-top: 20px; margin-bottom: 100px"
      v-if="isLoading == false && showFAQs == false"
    >
      <div class="row h-100">
        <div class="col-md-2"></div>
        <div class="col-md-8 text-start">
          <template v-if="customLanguage.faqLink">
            <div
              class="card-text"
              style="display: inline"
              v-html="customLanguage.faqLink.faqText"
            ></div>
            <a
              v-on:click="showDialog = true"
              style="color:#0d6efd; text-decoration: underline; display: inline;"
              >{{ customLanguage.faqLink.faqLinkText }}</a
            >
            <div class="card-text" v-html="customLanguage.faqLink.link"></div>
          </template>
          <template v-else>
            If you need help, please view our FAQs
            <span
              style="color: blue; font-weight: bold; cursor: pointer"
              v-on:click="showDialog = true"
              >here</span
            >.
          </template>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginView from "./components/LoginView.vue";
import OrderList from "./components/OrderList.vue";
import FAQView from "./components/FAQView.vue";
import RescheduleView from "./components/RescheduleView.vue";
import request from "request";
import axios from "axios";

export default {
  name: "app",
  components: {
    LoginView,
    OrderList,
    RescheduleView,
    FAQView,
  },
  methods: {
    getProjectName: function () {
      if (this.licenseDetails != null) {
        if (this.licenseDetails.name != null) {
          return this.licenseDetails.name + " - Delivery";
        }
      }
      return "CAT24 - Delivery";
    },
    getParameterByName: function (name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    fetchLicenseDetails: function () {
      var fetchWith = function (scope) {
        var licenseId = scope.getParameterByName("l");
        if (licenseId == null || licenseId == "") {
          scope.isLoading = false;
          return;
        }

        console.log(
          "FETCHING - " + scope.config.apiV2url + "/licence/" + licenseId
        );

        request(
          {
            headers: {
              "x-api-key": "6l3hMtOCKU1qhIcR0LqiZa3PSjxW8qg18EG9wSad",
            },
            url: scope.config.apiV2url + "/licence/" + licenseId,
            json: true,
            method: "GET",
          },
          function (error, response, recordObject) {
            //var recordObject = JSON.parse(recordData);

            if (error != null) {
              scope.isLoading = false;
              scope.errorText =
                "Error getting config - <" +
                scope.config.apiV2url.replace("https://", "") +
                ">";
              return;
            }

            if (recordObject["name"] == null) {
              scope.isLoading = false;
              return;
            }

            //ATTEMPT LOGIN VIA SESSION TOKEN...

            scope.licenseDetails = recordObject;
            scope.validateSessionToken();
            //scope.$set(scope,"fetchLicenseConnectionDetails", recordObject);
            //scope.isLoading = false;
          }
        );
      };

      fetchWith(this);
    },
    createUserDict: function (parseUser) {
      if (parseUser == null) {
        return {
          parseObject: null,
        };
      }

      return {
        parseObject: parseUser,
      };
    },
    validateSessionToken: function () {
      var validateWith = function (scope) {
        //Init Parse
        if (
          scope.getParameterByName("t") != null &&
          scope.getParameterByName("t") !== ""
        ) {
          // Parse.User.become(scope.getParameterByName("t")).then(function (user) {
          //     // The current user is now set to user.
          //     console.log("Logged in as - " + user.get("username"));
          //     scope.currentUser = scope.createUserDict(Parse.User.current());
          //     scope.isLoading = false;
          //
          // }, function (error) {
          //     // The token could not be validated.
          //     console.log(error);
          //     scope.isLoading = false;
          // });
          request(
            {
              url:
                scope.config.apiUrl +
                "/contact/" +
                scope.getParameterByName("l") +
                "/me",
              headers: {
                "x-cat24-contact-token": scope.getParameterByName("t"),
              },
              method: "GET",
            },
            function (error, response, recordData) {
              //var recordObject = JSON.parse(recordData);
              if (error != null) {
                // Removed as if you get this error the api is not reachable.
                //scope.isLoading = false;
                scope.apiError = true;
                scope.errorText =
                  "<" + scope.config.apiUrl.replace("https://", "") + ">";
                console.log(error);
                return;
              }
              var recordObject = JSON.parse(recordData);
              if (recordObject != null) {
                if (recordObject.error != null) {
                  scope.isLoading = false;
                  return;
                }
              }
              scope.currentUser = recordObject;
              scope.isLoading = false;
            }
          );

          return;
        }
        scope.isLoading = false;
      };

      validateWith(this);
    },
  },
  mounted: async function () {
    //     var faqUrl = 'https://x2lkx1cfjg.execute-api.eu-west-2.amazonaws.com/main/customfqaprod'
    //     if (process.env.VUE_APP_ENV == "dev"){
    //        faqUrl = 'https://x2lkx1cfjg.execute-api.eu-west-2.amazonaws.com/main/customfqa'
    //     }
    //     var FAQconfig = {
    //   method: 'get',
    //   url: faqUrl,
    //   headers: {
    //     'X-Cat24-Contact-Token': this.getParameterByName("t"),
    //     'X-Cat24-Contact-License': this.getParameterByName("l")
    //   }
    // };
    //     var cstomFaqData =  await axios(FAQconfig)
    //        this.customfaq = JSON.parse(cstomFaqData.data.body);

    // Get Config and Language Data

    const configURL =
      process.env.VUE_APP_APIV2URL +
      "/contact/" +
      this.getParameterByName("l") +
      "/config";
    const configuration = {
      method: "get",
      url: configURL,
      headers: { "x-api-key": "6l3hMtOCKU1qhIcR0LqiZa3PSjxW8qg18EG9wSad" },
    };
    const response = await axios(configuration);
    if (response.data?.orderingPortalLanguage) {
      this.customLanguage = response.data.orderingPortalLanguage;
    }
    // if (response.data?.deliveryPortalLanguage) {
    //   this.customLanguage = response.data.deliveryPortalLanguage;
    // }
  },
  data: function () {
    window.appInstance = this; //GLOBAL REFERENCE

    var runWith = function (scope) {
      setTimeout(function () {
        scope.fetchLicenseDetails();
      }, 200);
    };

    runWith(this);

    console.log(process.env);
    console.log("------");

    if (process.env.VUE_APP_ENV == "dev") {
      this.apiurl = "https://dev-catcoreapi.j24.io";
    } else {
      this.apiurl = "https://core.cat24.j24dev.uk";
    }

    return {
      currentView: "Overview",
      isLoading: true,
      showFAQs: false,
      licenseDetails: null,
      currentUser: null,
      rescheduleOrder: null,
      showDialog: false,
      customfaq: [],
      errorText: "",
      apiError: false,
      customLanguage: {},

      config: {
        companyName: "Cat24 Delivery",
        apiUrl: this.apiurl,
        //apiV2url: "https://cat24api.j24.io",
        apiV2url: process.env.VUE_APP_APIV2URL,
        logoSrc:
          this.apiurl + "/licence/" + this.getParameterByName("l") + "/logo",
      },
    };
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
