var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.showDialog)?[_c('div',{staticClass:"modal fade show",staticStyle:{"display":"block"}},[_c('div',{staticClass:"modal-dialog modal-xl"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("Cat24 FAQs")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){_vm.showDialog = false}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('FAQView')],1),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Close ")])])])])]),_c('div',{staticClass:"modal-backdrop show"})]:_vm._e(),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-md-2"}),_c('div',{staticClass:"col-md-8 text-start"},[_c('img',{staticStyle:{"max-height":"100px"},attrs:{"src":this.config.logoSrc,"alt":"Project Logo"}}),_c('BR'),(
            this.customLanguage.deliveryPortal &&
            this.customLanguage.deliveryPortal.headding
          )?[_c('h3',{staticClass:"ubuntu bold",staticStyle:{"margin-top":"20px"}},[_vm._v(" "+_vm._s(this.customLanguage.deliveryPortal.headding)+" ")])]:[_c('h3',{staticClass:"ubuntu bold",staticStyle:{"margin-top":"20px"}},[_vm._v(" Delivery Portal ")])],_c('hr')],2),_c('div',{staticClass:"col-md-2"})])]),(_vm.licenseDetails == null && _vm.isLoading == true)?_c('div',{staticClass:"container-fluid"},[_vm._m(0)]):_vm._e(),(_vm.licenseDetails == null && _vm.isLoading == false)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-4 my-auto"},[_c('div',{staticClass:"card card-block mx-auto"},[_c('div',{staticClass:"card-header bg-danger text-white"},[_vm._v("Invalid License")]),_c('div',{staticClass:"card-body text-center"},[_c('p',{staticClass:"card-text"},[_vm._v(" Please double check the link and try again. ")]),_c('br'),_c('span',{staticClass:"fa fa-warning"}),_c('br'),_vm._v(" "+_vm._s(_vm.errorText)+" ")])])]),_c('div',{staticClass:"col-md-4"})])]):_vm._e(),(_vm.apiError)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-4 my-auto"},[_c('div',{staticClass:"card card-block mx-auto"},[_c('div',{staticClass:"card-header bg-danger text-white"},[_vm._v("Connection Error")]),_c('div',{staticClass:"card-body text-center"},[_c('p',{staticClass:"card-text"},[_vm._v("Unable to connect, please retry later.")]),_c('br'),_c('span',{staticClass:"fa fa-warning"}),_c('br'),_vm._v(" "+_vm._s(_vm.errorText)+" ")])])]),_c('div',{staticClass:"col-md-4"})])]):_vm._e(),(_vm.licenseDetails != null && _vm.isLoading == false && _vm.currentUser == null)?_c('LoginView'):_vm._e(),(
      _vm.isLoading == false && _vm.currentUser != null && _vm.rescheduleOrder == null
    )?_c('OrderList'):_vm._e(),(
      _vm.isLoading == false && _vm.currentUser != null && _vm.rescheduleOrder != null
    )?_c('RescheduleView'):_vm._e(),(_vm.isLoading == false && _vm.showFAQs == true)?_c('FAQView'):_vm._e(),(_vm.isLoading == false && _vm.showFAQs == false)?_c('div',{staticClass:"container-fluid",staticStyle:{"margin-top":"20px","margin-bottom":"100px"}},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-md-2"}),_c('div',{staticClass:"col-md-8 text-start"},[(_vm.customLanguage.faqLink)?[_c('div',{staticClass:"card-text",staticStyle:{"display":"inline"},domProps:{"innerHTML":_vm._s(_vm.customLanguage.faqLink.faqText)}}),_c('a',{staticStyle:{"color":"#0d6efd","text-decoration":"underline","display":"inline"},on:{"click":function($event){_vm.showDialog = true}}},[_vm._v(_vm._s(_vm.customLanguage.faqLink.faqLinkText))]),_c('div',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(_vm.customLanguage.faqLink.link)}})]:[_vm._v(" If you need help, please view our FAQs "),_c('span',{staticStyle:{"color":"blue","font-weight":"bold","cursor":"pointer"},on:{"click":function($event){_vm.showDialog = true}}},[_vm._v("here")]),_vm._v(". ")]],2),_c('div',{staticClass:"col-md-2"})])]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-4 my-auto"},[_c('div',{staticClass:"card card-block mx-auto"},[_c('div',{staticClass:"card-header bg-warning"},[_vm._v("Loading")]),_c('div',{staticClass:"card-body text-center"},[_c('p',{staticClass:"card-text"},[_vm._v("Please wait. Fetching license details.")]),_c('br'),_c('i',{staticClass:"fas fa-spinner fa-spin"})])])]),_c('div',{staticClass:"col-md-4"})])}]

export { render, staticRenderFns }