<template>
  <div class="container-fluid">

    <div class="row h-100" v-if="loading == true" >
      <div class="col-md-2"></div>
      <div class="col-md-8 my-auto">
        <div class="card card-block mx-auto">
          <div class="card-header bg-warning">
            Loading
          </div>
          <div class="card-body text-center">
            <p class="card-text">Hi {{fetchCurrentUser().firstName}},<br/>Please wait, retrieving your order information.</p>
            <i class="fas fa-spinner fa-spin"></i>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>

    <div class="row h-100" v-if="loading == false && orders != null"  style="margin-bottom:100px;">
      <div class="col-md-2"></div>
      <div class="col-md-8 my-auto">
        <div class="card card-block mx-auto">
          <div class="card-header text-start">
            Your Orders
          </div>
          <div class="card-body text-start">
              <OrderView  v-for="order in orders" :key="order.objectId" :order="order"></OrderView>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script>
import request from 'request'
import OrderView from './OrderView.vue'
export default {
  name: 'OrderList',
  components : {
    OrderView
  },
  methods: {
    fetchCurrentUser : function(){
      return this.$parent.currentUser;
    },
    rescheduleOrder : function(order){
      this.$parent.rescheduleOrder = order;
    },
    getParameterByName: function(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    allowanceDate : function(order) {
      if (order.deliverySlot == null) {
        return new Date();
      }
      var allowanceDate = new Date(Date.parse(order.deliverySlot.deliveryDate.iso));
      allowanceDate.setHours(allowanceDate.getHours() - order.deliverySlot.rescheduleAllowance);

      return allowanceDate;
    },
    orderIsEditable : function(order) {
      // if (order.contactApproved == true){
      //   return false;
      // }
      console.log(order);
      if (order.deliverySlot == null) {
        console.log("NO DELIVERY SLOT");
        return true;
      }
      var now = new Date();
      var allowanceDate = new Date(Date.parse(order.deliverySlot.deliveryDate.iso));
      allowanceDate.setHours(allowanceDate.getHours() - order.deliverySlot.rescheduleAllowance);

      if (allowanceDate < now) {
        return false
      }

      return true;
    },
    orderType : function(order) {

      if (order.deliverySlot == null) {
        return "Pending";
      }
      var now = new Date();
      var allowanceDate = new Date(Date.parse(order.deliverySlot.deliveryDate.iso));
      allowanceDate.setHours(allowanceDate.getHours() - order.deliverySlot.rescheduleAllowance);

      if (order.contactApproved == true){
        if (allowanceDate < now) {
          return "Previous"
        }
        return "Confirmed";
      }
      if (allowanceDate < now) {
        return "Missed"
      }
      return "Pending";
    },
    prettyDate : function(iso) {
      var dateFormat = require('dateformat');
var now = Date.parse(iso);
return dateFormat(now, "dddd, mmmm dS, yyyy");
    },
    styleForOrder : function(order) {
        if (order.contactApproved == true){
          return {
            "bg-success" : true,
            "card-header" : true,
            "text-white" : true
          }
        }
        return {
          "bg-warning" : true,
          "card-header" : true,
          "text-white" : true
        }
    },
    fetchCustomFields : function() {
      var fetchWith = function(scope) {
        request({ url:  scope.$parent.config.apiUrl + "/contact/" + scope.getParameterByName("l") + "/fields",headers: {
          "x-cat24-contact-token" : scope.getParameterByName("t")
        }, method: 'GET'}, function(error, response, recordData) {
          var recordObject = JSON.parse(recordData);

          if (error != null) {
            scope.loading = false;
            return;
          }

          if (recordObject != null){
            if (recordObject.error != null){
              scope.loading = false;
              return;
            }
          }
          scope.fields = recordObject.fields;
          scope.loading = false;
          scope.fetchOrderDetails();
        });
      }
      fetchWith(this);
    },
    fetchOrderDetails : function(){

      var fetchWith = function(scope) {
        request({ url:  scope.$parent.config.apiUrl + "/contact/" + scope.getParameterByName("l") + "/orders",headers: {
          "x-cat24-contact-token" : scope.getParameterByName("t")
        }, method: 'GET'}, function(error, response, recordData) {


          var recordObject = JSON.parse(recordData);

          if (error != null) {
            scope.loading = false;
            return;
          }

          if (recordObject != null){
            if (recordObject.error != null){
              scope.loading = false;
              return;
            }
          }
          scope.orders = recordObject.orders;
          scope.loading = false;
        });
      }
      fetchWith(this);
    },
  approveOrder : function(order){
    window.__vue.set(order, "loading", true);
    var fetchWith = function(scope) {
      request({ url:  scope.$parent.config.apiUrl + "/contact/" + scope.getParameterByName("l") + "/orders/" + order.objectId + "/approve",headers: {
        "x-cat24-contact-token" : scope.getParameterByName("t")
      }, method: 'GET'}, function() {
        scope.fetchOrderDetails();
      });
    }
    fetchWith(this);
  }
},
  data: function(){
    var runWith = function(scope){
      setTimeout(function(){
        scope.fetchCustomFields();
      }, 200);
    }

    runWith(this);
    return {
      loading : true,
      orders : null,
      fields : null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
