<template>
  <div class="leftallign">

            <p > Before you contact our support team, please read the following -</p>
              <ul>
                <li>Have you tried using another device?</li>
                <ul>
                  <li>Older devices may have issues with this website. Trying to use this with a smart phone or tablet could help improve your experience.</li>
                </ul>
                <li>Have you made sure you’re using the correct email address to login?</li>
                <ul>
                  <li>Only registered eligible emails will work on this portal, please make sure you enter the same email that we used to deliver your invitation to this portal.</li>
                </ul>
                <li>Have you got a stable internet connection?</li>
                <li>Have you checked your email spam folder?</li>
                <li>Have you tried restarting your device?</li>
              </ul>
  </div>
</template>

<script>

export default {
  name: 'FAQView',
  data: function(){
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ul { padding-right: 1.2em; }

.leftallign {
  text-align: left !important;
}

</style>
