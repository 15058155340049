<template>
  <div class="container-fluid">

    <div class="row h-100" v-if="loading == true && dates == null">
      <div class="col-md-2"></div>
      <div class="col-md-8 my-auto">
        <div class="card card-block mx-auto">
          <div class="card-header bg-warning">
            Loading
          </div>
          <div class="card-body text-center">
            <p class="card-text">Hi {{fetchCurrentUser().firstName}},<br/>Please wait, retrieving alternate dates.</p>
            <div v-if="loading == true && dates == null"><i class="fas fa-spinner fa-spin"></i></div>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>

    <div class="row h-100" v-if="rescheduling == true" >
      <div class="col-md-2"></div>
      <div class="col-md-8 my-auto">
        <div class="card card-block mx-auto">
          <div class="card-header bg-warning">
            Please Wait
          </div>
          <div class="card-body text-center">
            <p class="card-text">Hi {{fetchCurrentUser().firstName}},<br/>Please wait, we are resheduling your delivery.</p>
            <div v-if="rescheduling == true"><i class="fas fa-spinner fa-spin"></i></div>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>


    <div class="row h-100" v-if="dates != null && !rescheduling"  style="margin-bottom:100px;">
      <div class="col-md-2"></div>
      <div class="col-md-8 my-auto">
        <div class="card card-block mx-auto">
          <div class="card-header text-start">
            Choose a delivery day
            <button v-on:click="cancel()" class="btn btn-sm bg-warning float-end">Cancel</button>
          </div>
          <div class="card-body text-start">
              <table class="table text-center">
                <thead>
                <tr>
                  <th class="text-start">Delivery Date</th>
                  <th>Available Slots</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="slot in dates" v-bind:key="slot.objectId">
                  <td class="text-start">{{prettyDate(slot.deliveryDate.iso)}}</td>
                  <td><span v-if="!canBook(slot)">Full</span><span v-if="canBook(slot)">{{slot.availableSlots}}</span></td>
                  <td><button v-if="canBook(slot)" class="btn bg-primary text-white" v-on:click="bookSlot(slot)">Choose</button></td>
                <tr/>
                <tr v-if="canLoadMore" v-on:click="loadMore()" style="cursor:pointer;font-weight:bold;">
                  <td colspan="3">Load More <div  v-if="loading"><i class="fas fa-spinner fa-spin"></i></div></td>
                </tr>
              </tbody>
              </table>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row h-100" v-if="dates == null && loading == false && !rescheduling"  style="margin-bottom:100px;">
      <div class="col-md-2"></div>
      <div class="col-md-8 my-auto">
        <div class="card card-block mx-auto">
          <div class="card-header text-start">
            Choose a delivery day
            <button v-on:click="cancel()" class="btn btn-sm bg-warning float-end">Cancel</button>
          </div>
          <div class="card-body text-start">
            No Dates Available
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script>
import request from 'request'
import loging from '../components/loging.js';

export default {
  name: 'RescheduleView',
  methods: {
    fetchCurrentUser : function(){
      return this.$parent.currentUser;
    },
    cancel : function(){
      this.$parent.rescheduleOrder = null;
    },
    getParameterByName: function(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    prettyDate : function(iso) {
      var dateFormat = require('dateformat');
var now = Date.parse(iso);
return dateFormat(now, "dddd, mmmm dS, yyyy");
},
loadMore : function(){
  this.skip += this.limit;
  this.fetchNext();
},
    fetchNext : function(){

      var fetchWith = function(scope) {
        scope.loading = true;
        request({ url:  scope.$parent.config.apiUrl + "/contact/" + scope.getParameterByName("l") + "/delivery/availableslots?skip=" + scope.skip + "&limit=" + scope.limit,headers: {
          "x-cat24-contact-token" : scope.getParameterByName("t")
        }, method: 'GET'}, function(error, response, recordData) {


          var recordObject = JSON.parse(recordData);

          if (error != null) {
            scope.loading = false;
            return;
          }

          if (recordObject != null){
            if (recordObject.error != null){
              scope.loading = false;
              return;
            }
          }
          if (scope.dates == null) {
            scope.dates = [];
          }
          scope.dates = scope.dates.concat(recordObject.slots);
          if (recordObject.slots.length < scope.limit) {
            scope.canLoadMore = false;
          }else {
            scope.canLoadMore = true;
          }

          scope.loading = false;
        });
      }
      fetchWith(this);
    },
    canBook : function(slot) {
      var now = new Date();
      var allowanceDate = new Date(Date.parse(slot.deliveryDate.iso));
      allowanceDate.setHours(allowanceDate.getHours() - slot.rescheduleAllowance);
      if (allowanceDate < now) {
        return false;
      }
      if (slot.availableSlots <= 0){
        return false;
      }
      return true;
    },
    bookSlot : function(slot) {
      var bookWith = async function(scope) {
        scope.rescheduling = true;
        
        await loging.sumo({"page": "orderView-Slot","licence":  scope.getParameterByName("l"),  "token": scope.getParameterByName("t"), "order": scope.$parent.rescheduleOrder}, scope.$parent.licenseDetails.loggingurl )

        request({ url:  scope.$parent.config.apiUrl + "/contact/" + scope.getParameterByName("l") + "/orders/" + scope.$parent.rescheduleOrder.objectId + "/reschedule",headers: {
          "x-cat24-contact-token" : scope.getParameterByName("t")
        },json : {
          "slotIdentifier" : slot.objectId
        }, method: 'POST'}, function(error, response, recordObject) {


          if (error != null) {
            scope.rescheduling = false;
            return;
          }

          if (recordObject != null){
            if (recordObject.error != null){
              scope.error = recordObject.error;
              scope.rescheduling = false;
              return;
            }
          }

            scope.rescheduling = false;
            scope.cancel();
        });
      }
      bookWith(this);
    }
  // approveOrder : function(order){
  //   window.__vue.set(order, "loading", true);
  //   var fetchWith = function(scope) {
  //     request({ url:  scope.$parent.config.apiUrl + "/contact/" + scope.getParameterByName("l") + "/orders/" + order.objectId + "/approve",headers: {
  //       "x-cat24-contact-token" : scope.getParameterByName("t")
  //     }, method: 'GET'}, function(error, response, recordData) {
  //       scope.fetchOrderDetails();
  //     });
  //   }
  //   fetchWith(this);
  // }
},
  data: function(){
    var runWith = function(scope){
      setTimeout(function(){
        scope.fetchNext();
      }, 200);
    }

    runWith(this);
    return {
      error : null,
      limit : 4,
      skip : 0,
      loading : true,
      rescheduling : false,
      canLoadMore : false,
      dates : null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
