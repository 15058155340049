
import App from './App.vue'
import Vue from 'vue';
import VueRouter from 'vue-router';
import 'bootstrap';
import VueMeta from 'vue-meta'
require('bootstrap/dist/css/bootstrap.min.css');
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
//import "font-awesome-webpack";
import VueGmaps from 'vue-gmaps'

window.__vue = Vue;
window.apiUrl = "https://delivery.cat24.j24dev.uk"
Vue.use(VueRouter);
Vue.use(VueMeta);


Vue.use(VueGmaps, {
  key: 'AIzaSyA_2ykrxOW-pTVmHo0RC6pW3zA1kmRwHfA'
})

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
  ]
});

new Vue({
  router : router,
  render: function(createElement){
    window.appRoot = createElement(App);
    console.log("Created App Root.");
    console.log(window.appRoot.notifications);
    return window.appRoot;
  }
}).$mount('#app');
