<template>
    <div class="container-fluid">

        <div class="row h-100" v-if="loading == true" >
            <div class="col-md-2"></div>
            <div class="col-md-8 my-auto">
                <div class="card card-block mx-auto">
                    <div class="card-header bg-warning">
                        Loading
                    </div>
                    <div class="card-body text-center">
                        <p class="card-text">Please wait, this could take a few moments.</p>
                        <i class="fas fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>

        <div class="row h-100" v-if="loading == false && pendingEmail == false">
            <div class="col-md-2"></div>
            <div class="col-md-8 my-auto">
                <div class="card card-block mx-auto">
                    <div class="card-header bg-warning">
                        Please Login
                    </div>
                    <div class="card-body text-center">
                        <p class="card-text">To continue, please enter a valid email address. You should use the same email address that received the invitation for this portal.</p>
                        <p class="card-text">We will send you a unique access link via email.</p>

                        <p class="card-text" style="font-style: italic;">By providing your details you agree for Jigsaw24 to process this information. We’ll always treat your details with respect and the information you provide will only be used for the provision of this service. You can read Jigsaw24’s full Privacy Notice <a href="https://www.jigsaw24.com/privacy-notice">here</a>.</p>
                        <p v-if="fatalError != null" class="card-text" style="font-style: italic;color: red;">{{fatalError}}</p>
                        <label for="email" class="form-label">Your Email:</label>
                        <div class="input-group">
                            <input id="email" class="form-control" type="email" width="100%" v-model="email" placeholder="Email"/>
                            <div class="input-group-append">
                                <button type="button" class="btn btn-outline-secondary" v-on:click="triggerLoginEmail()">Continue</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>


        <div class="row h-100" v-if="fatalError == null && loading == false && fetchCurrentUser() == null && pendingEmail == true" >
            <div class="col-md-2"></div>
            <div class="col-md-8 my-auto">
                <div class="card card-block mx-auto">
                    <div class="card-header bg-warning">
                        Check your email.
                    </div>
                    <div class="card-body text-center">
                        <template
            v-if="$parent.customLanguage.SuccessPage"
          >
            <div
              class=""
              v-html="
                $parent.customLanguage.SuccessPage.body
              "
            ></div>
          </template>
          <template v-else>
                        <p class="card-text">We have emailed you. Please make sure you check your spam folder.</p>
                        <p class="card-text">Please allow up to 15 minutes for this to arrive.</p>
                        <p class="card-text">You can now close this window.</p>
           </template>
                    </div>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</template>

<script>
    import request from 'request'

export default {
    name: 'LoginView',
    methods: {

        fetchCurrentUser : function(){
          return this.$parent.currentUser;
        },
        getParameterByName: function(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
        triggerLoginEmail : function(){

            if (this.email == null){
                //bootbox is a JS plugin that lets us display pretty popups!

                return;
            }


            var loginWith = function(scope){
                console.log("Logging In...");
                scope.loading = true;


                request({ url:  scope.$parent.config.apiUrl + "/contact/" + scope.getParameterByName("l") + "/login",json : {
                        email : scope.email
                    }, method: 'POST'}, function(error, response, recordObject) {


                    //var recordObject = JSON.parse(recordData);

                    if (error != null) {
                        scope.loading = false;
                        scope.fatalError = "Error logging you in.";
                        return;
                    }

                  if (recordObject != null){
                    if (recordObject.error != null){
                        scope.loading = false;
                        scope.fatalError = "Error - " + recordObject.error;
                        return;
                    }
                  }

                    scope.loading = false;
                    scope.pendingEmail = true;
                });

            }

            //Below we call the function created above to preserve the current scope. When calling Parse block methods, the current scope can be lost.
            //This is why we set the function 'scope' variable to 'this'. We can now access the current scope (this) at anytime within that function.
            this.fatalError = null;
            loginWith(this);

        }
    },
    data: function(){
        console.log("THIS: ", this);
        return {
            email : null,
            pendingEmail : false,
            loading : false,
            fatalError : null
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
