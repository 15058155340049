    <template>
      <div>



      <div class="vld-parent">
        <loading :active.sync="isLoading" 
        :is-full-page="fullPage"></loading>
        
    </div>

           <!--  Modal Start -->

           <div id="modal" class="modal">
  <!-- Modal content -->
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-header-text">Booking Confirmation</h3>
    </div>
    <div class="modal-body">
      <p> Are you sure you want to book your appointment on the <b>{{bookingDate}}</b> at <b>{{bokingtime}}</b> </p>
      <p> We will send a copy of the infration to your email.</p>
    </div>
    <div class="modal-footer">
       <button type="button" class="btn btn-secondary" @click="closeModal()" >No, Back</button>
          <button type="button" class="btn btn-primary" @click="bookAppointment()">Yes, Book Appointment</button>
    </div>
  </div>
</div>

  <!-- modal end -->

     <div id="errorModal" class="modal">
    <!-- Modal content -->
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-header-text">Information</h3>
    </div>
    <div class="modal-body">
      <p>{{infoMsg}} </p>
      
    </div>
    <div class="modal-footer">
       <button type="button" class="btn btn-secondary" @click="closeErrorModal()" >Ok</button>
    </div>
  </div>
</div>

  <!-- modal end -->

              <h5>Office Delivery</h5>

<template v-if="bookingDate == ''">
<datatable title="Available Booking Dates" :columns="tableColumnsDays" :rows="data" :printable="false" :searchable="false" :exportable="false" :defaultPerPage="5" :sortable="false">
  <th slot="thead-tr" >
    
  </th>
  <template slot="tbody-tr" scope="props">
    <td>
      <button class="btn bg-primary text-white "
          @click="(e) => chooseTime(props.row, e)">
        Choose Date
      </button>
    </td>
  </template>
</datatable>
</template>

<template v-if="bookingDate != ''">
<datatable v-bind:title="'Available Booking Times for '+bookingDate" :columns="tableColumnsTimes" :rows="timeRows" :printable="false" :searchable="false" :exportable="false" :defaultPerPage="5" :sortable="false">
  <th slot="thead-tr">
    
  </th>
  <template slot="tbody-tr" scope="props">
    <td>
      <button class="btn bg-primary text-white "
          @click="(e) => bookSlot(props.row, e)">
        Choose Time
      </button>
    </td>
  </template>
</datatable>

<button  v-on:click="backToDays()" class="btn bg-primary text-white float-end btnspace">Back to Days</button>
</template>

     <!--  <div class="card-body text-start">
              <table class="table text-center">
                <thead>
                <tr>
                  <th class="text-start">Collection Date</th>
                  <th>Available Slots</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="slots, slotdate in data.bookingSlots" :key="slotdate" >
                  <td class="text-start">{{formatDate(slotdate)}}</td>
                  <td class="text-start">{{slots}}</td>
                 

                <tr/>
                <tr v-if="canLoadMore" v-on:click="loadMore()" style="cursor:pointer;font-weight:bold;">
                  <td colspan="3">Load More <div  v-if="loading"><i class="fas fa-spinner fa-spin"></i></div></td>
                </tr>
              </tbody>
              </table>
          </div> -->

  </div>


  </template>

  <script type="text/javascript">
  const { DateTime } = require("luxon");
  import DataTable from "vue-materialize-datatable";
import axios from 'axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

     export default {
    name: 'OfficeDelivery',
      props : ["data","timeslots"],
      mounted: function(){
    console.log(this.data)
   },
   data: function(){
    return {
      canLoadMore: true,
      loading: false,
      bookingDate: '',
      timeRows: [],
      showmodel: true,
      bookingSlotValue: '',
      infoMsg: '',
      bokingtime: '',
      isLoading: false,
      fullPage: true,
      tableColumnsDays:  [
      {
        label: "Appointment Date",
        field: "appTime",
        numeric: false,
        html: false
      }
      ],
    
    tableColumnsTimes:  [
      {
        label: "Appointment Time",
        field: "time",
        numeric: false,
        html: false
      }
      ]
    }

  },
  components: {
      "datatable": DataTable,
      Loading
  },
  methods: {
    formatDate : function(date){
      return  DateTime.fromISO(date).toFormat('EEEE dd MMM yyyy HH:mm')
    },
    chooseTime: function(data){
      this.bookingDate = data.appTime
      this.timeRows = this.timeslots[data.appTime.replace(/ /g, '')]
    },
    backToDays: function(){
      this.bookingDate = ''
    },
    closeModal: function(){
      document.getElementById("modal").style.display = "none";
    },
    closeErrorModal: function(){
      document.getElementById("errorModal").style.display = "none";
    },
    bookSlot: function(data){
      var bookingSlotValue = DateTime.fromFormat(this.bookingDate + " "+data.time, 'EEEE dd MMM yyyy HH:mm' ).toISO()
      this.bokingtime = data.time
      this.bookingSlotValue = bookingSlotValue
      document.getElementById("modal").style.display = "block";
    },
    bookAppointment: async function(){
      var orderid = this.$parent.order.objectId;
      this.isLoading = true;
        await axios.post(this.$parent.$parent.$parent.config.apiV2url +"/bookings/bookslot",{'slot':this.bookingSlotValue, 'orderId':orderid} ,{headers: {
        "x-cat24-contact-token" : this.$parent.getParameterByName("t"), 'X-Cat24-Contact-License': this.$parent.getParameterByName("l")
      }} ).then((response) => {
        this.isLoading = false;
        this.closeModal();
        if (response.status !== 200) {
          this.infoMsg = "An error has occurred while booking your appointment. Please try again, if the error persists contact support."
          document.getElementById("errorModal").style.display = "block";
        } else {
          this.infoMsg = ''
          location.reload()
      }
        })

    }
  }
}
   
  </script>

  <style scoped>

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 60%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 10px 16px;
  background-color: #fff;
  color: white;
}

.modal-header-text {
color: #000;
padding-top: 10px;

}

.modal-body {
  padding-top: 10px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #fff;
  color: white;
}

.btnspace {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>